import React from "react";
import { tw } from "twind";

type Props = {};

const Footer = (props: Props) => {
  return (
    <>
      <div className={tw`mx-auto py-3`}>
        © 2024 - <span className={tw`text-[#4D56AC]`}>Dibtech Marketing</span> -
        All Right Reserved | Powered by{" "}
        <a
          href="https://dibtech.com.au/"
          target="_blank"
          className={tw`text-[#4D56AC]`}
        >
          Dibtech Business Solutions
        </a>
      </div>
    </>
  );
};

export default Footer;
