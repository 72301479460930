import React from "react";

const EmailCampaignSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3332 6.48334V15.6667H0.666504V2.33334H10.7498C10.6998 2.6 10.6665 2.88334 10.6665 3.16667C10.6665 4.4 11.2082 5.49167 12.0582 6.25834L8.99984 8.16667L2.33317 4V5.66667L8.99984 9.83334L13.4165 7.06667C13.8665 7.23334 14.3332 7.33334 14.8332 7.33334C15.7748 7.33334 16.6332 7.00834 17.3332 6.48334ZM12.3332 3.16667C12.3332 4.55 13.4498 5.66667 14.8332 5.66667C16.2165 5.66667 17.3332 4.55 17.3332 3.16667C17.3332 1.78334 16.2165 0.666672 14.8332 0.666672C13.4498 0.666672 12.3332 1.78334 12.3332 3.16667Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3332 6.48334V15.6667H0.666504V2.33334H10.7498C10.6998 2.6 10.6665 2.88334 10.6665 3.16667C10.6665 4.4 11.2082 5.49167 12.0582 6.25834L8.99984 8.16667L2.33317 4V5.66667L8.99984 9.83334L13.4165 7.06667C13.8665 7.23334 14.3332 7.33334 14.8332 7.33334C15.7748 7.33334 16.6332 7.00834 17.3332 6.48334ZM12.3332 3.16667C12.3332 4.55 13.4498 5.66667 14.8332 5.66667C16.2165 5.66667 17.3332 4.55 17.3332 3.16667C17.3332 1.78334 16.2165 0.666672 14.8332 0.666672C13.4498 0.666672 12.3332 1.78334 12.3332 3.16667Z"
            fill="#323232"
          />
        </svg>
      )}
    </>
  );
};

export default EmailCampaignSVG;
