import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import usePostHook from "../../customHooks/usePostHook";
import moment from "moment";
import APIS from "../../constants/EndPoint";

type Props = {};
const QuickEmailComponent: any = Loadable({
  loader: () => import("../../components/QuickEmail/QuickEmail"),
  loading: Loader,
});

const QuickEmail = () => {
  const { isPostLoading, mutateAsync } = usePostHook({
    queryKey: ["quick-email"],
    navigateURL: "",
  });

  const handleQuicKEmailSubmit = async (data: any, reset: any) => {
    const dateTime =
      data.selection === "now"
        ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
        : moment(data.laterdate).format("YYYY-MM-DDTHH:mm:ssZ");
    const formData = {
      sender: data.sender,
      recipient: data.recipient,
      subject: data.subject,
      content: data.content,
      schedule: dateTime,
    };
    try {
      await mutateAsync({
        url: `${APIS.QUICK_EMAIL}`,
        formData: formData,
      });
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <QuickEmailComponent
        handleQuicKEmailSubmit={handleQuicKEmailSubmit}
        isPostLoading={isPostLoading}
      />
    </>
  );
};

export default QuickEmail;
