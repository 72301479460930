const ContactSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.6665 0H2.33317V1.66667H15.6665V0ZM2.33317 20H15.6665V18.3333H2.33317V20ZM17.3332 3.33333H0.666504V16.6667H17.3332V3.33333ZM8.99984 5.625C10.0332 5.625 10.8748 6.46667 10.8748 7.5C10.8748 8.53333 10.0332 9.375 8.99984 9.375C7.9665 9.375 7.12484 8.53333 7.12484 7.5C7.12484 6.46667 7.9665 5.625 8.99984 5.625ZM13.1665 14.1667H4.83317V12.9167C4.83317 11.525 7.60817 10.8333 8.99984 10.8333C10.3915 10.8333 13.1665 11.525 13.1665 12.9167V14.1667Z"
            fill="#ffffff"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.6665 0H2.33317V1.66667H15.6665V0ZM2.33317 20H15.6665V18.3333H2.33317V20ZM17.3332 3.33333H0.666504V16.6667H17.3332V3.33333ZM8.99984 5.625C10.0332 5.625 10.8748 6.46667 10.8748 7.5C10.8748 8.53333 10.0332 9.375 8.99984 9.375C7.9665 9.375 7.12484 8.53333 7.12484 7.5C7.12484 6.46667 7.9665 5.625 8.99984 5.625ZM13.1665 14.1667H4.83317V12.9167C4.83317 11.525 7.60817 10.8333 8.99984 10.8333C10.3915 10.8333 13.1665 11.525 13.1665 12.9167V14.1667Z"
            fill="#323232"
          />
        </svg>
      )}
    </>
  );
};

export default ContactSVG;
