import React from "react";
import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";

type Props = {};
const DashboardComponent: any = Loadable({
  loader: () => import("../../components/Dashboard"),
  loading: Loader,
});

const Dashboard = () => {
  return (
    <>
      <DashboardComponent />
    </>
  );
};

export default Dashboard;
