const QuickSmsSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3332 0.666664H0.666504V17.3333L3.99984 14H17.3332V0.666664ZM6.49984 8.16666H4.83317V6.5H6.49984V8.16666ZM9.83317 8.16666H8.1665V6.5H9.83317V8.16666ZM13.1665 8.16666H11.4998V6.5H13.1665V8.16666Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3332 0.666664H0.666504V17.3333L3.99984 14H17.3332V0.666664ZM6.49984 8.16666H4.83317V6.5H6.49984V8.16666ZM9.83317 8.16666H8.1665V6.5H9.83317V8.16666ZM13.1665 8.16666H11.4998V6.5H13.1665V8.16666Z"
            fill="#323232"
          />
        </svg>
      )}
    </>
  );
};

export default QuickSmsSVG;
