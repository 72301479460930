import hamburgericon from "../../../assets/icons/hamburger.svg";
import notificationicon from "../../../assets/icons/notification.svg";
import helpicon from "../../../assets/icons/help.svg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import SidebarButton from "../Button/SidebarButton";
import Avatar from "../Avatar/Avatar";
import { tw } from "twind";

type Props = {
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar = (props: Props) => {
  const { setIsOpenSidebar } = props;
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const newTitle = pathname.split("/")[1].replace("-", " ");

  return (
    <>
      <div
        className={tw`flex justify-between border px-2 py-3 bg-[#4D56AC] sticky top-0 z-10 mx-3 my-4 rounded-lg text-white`}
      >
        <div className={tw`flex items-center`}>
          <div className={tw`lg:hidden pt-1`}>
            <SidebarButton
              icon={hamburgericon}
              setIsOpenSidebar={setIsOpenSidebar}
            />
          </div>
          <h2 className={tw`font-medium pl-3 text-xl capitalize`}>
            {newTitle === ""
              ? "Dashboard"
              : `${newTitle}${
                  searchParams.get("selected")
                    ? ` - ${searchParams.get("selected")}`
                    : ""
                }`}
          </h2>
        </div>
        <div className={tw`flex items-center gap-x-4 pr-2`}>
          <Link
            to="/billing"
            className={tw`bg-white px-3 py-1.5 rounded-lg text-black`}
          >
            Balance: $520 +
          </Link>
          <div className={tw`flex gap-x-2`}>
            <span>
              <img src={helpicon} alt="helpicon" />
            </span>
            <span>
              <img src={notificationicon} alt="helpicon" />
            </span>
          </div>
          <Avatar />
        </div>
      </div>
    </>
  );
};

export default Navbar;
