import React from "react";
import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";

type Props = {};
const BillingComponent: any = Loadable({
  loader: () => import("../../components/Billing/Billing"),
  loading: Loader,
});

const Billing = (props: Props) => {
  return (
    <>
      <BillingComponent />
    </>
  );
};

export default Billing;
