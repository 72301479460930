import { useQuery } from "@tanstack/react-query";
import { getApiData } from "../helpers/axiosInstance";
import { toast } from "react-toastify";

const useGetHook = ({
  queryKey,
  url,
  params,
  isEnabled = true,
  isKeepPrevious = true,
}) => {
  const { isLoading, isError, data, isSuccess } = useQuery(
    queryKey,
    async () => {
      try {
        const response = await getApiData(url, params);
        if (response?.status >= 400) {
          toast.error(
            response?.data?.group?.[0]
              ? "Please select a group first"
              : response?.data?.detail
              ? "Group not found"
              : "Something went wrong"
          );
        }
        return response.data;
      } catch (err) {
        toast.error("Network error or request failed");
        throw err;
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: isEnabled,
      keepPreviousData: isKeepPrevious,
    }
  );

  return { isLoading, isError, data, isSuccess };
};

export default useGetHook;
