import React from "react";
import { tw } from "twind";

type Props = {
  icon: any;
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarButton = (props: Props) => {
  const { setIsOpenSidebar, icon } = props;
  return (
    <div>
      <button onClick={() => setIsOpenSidebar((pre) => !pre)}>
        <img src={icon} alt="abc" className={tw`w-6`} />
      </button>
    </div>
  );
};

export default SidebarButton;
