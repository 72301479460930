import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { postApiData } from "../helpers/axiosInstance";
// import { postApiData } from "../helpers/axiosInstance";

const usePostHook = ({ queryKey, navigateURL }) => {
  //  Getting cached data from queryClient
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    isLoading: isPostLoading,
    mutateAsync,
    isSuccess: isPostSuccess,
    data: postData,
    isError: isPostError,
  } = useMutation(postApiData, {
    onSuccess: (data) => {
      if (data.status === 201 || data.status === 200) {
        toast.success(data.data.detail ?? "Item created successfully");
        if (navigateURL) {
          navigate(navigateURL);
        }
      } else {
        if (data?.data?.non_field_errors) {
          toast.error(data?.data?.non_field_errors[0]);
        } else {
          toast.error(data?.data?.message ?? "Something went wrong");
        }
      }
    },
    onError: (error: any) => {
      if (error.data && error.data.addresses) {
        const { city, line_address_one, country } = error.data.addresses[0];
        if (city) {
          toast.error("City is required field");
        }
        if (line_address_one) {
          toast.error("Line address one is required field");
        }
        if (country) {
          toast.error("Country is required field");
        }
      } else if (error.data && error.data.name[0]) {
        toast.error(error.data.name[0] || "Something went wrong");
      } else {
        toast.error("Something went wrong");
      }
    },

    onSettled: () => {
      if (queryKey && Array.isArray(queryKey)) {
        queryKey.forEach((key) => {
          queryClient.invalidateQueries(key);
        });
      }
    },
  });
  return {
    isPostLoading,
    mutateAsync,
    isPostSuccess,
    postData,
    isPostError,
  };
};

export default usePostHook;
