const BillingSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 3.83333H3.83333V15.5H15.5V3.83333ZM13.8333 5.5V7.16667H5.5V5.5H13.8333ZM8.83333 10.5V8.83333H10.5V10.5H8.83333ZM10.5 12.1667V13.8333H8.83333V12.1667H10.5ZM7.16667 10.5H5.5V8.83333H7.16667V10.5ZM12.1667 8.83333H13.8333V10.5H12.1667V8.83333ZM5.5 12.1667H7.16667V13.8333H5.5V12.1667ZM12.1667 13.8333V12.1667H13.8333V13.8333H12.1667ZM3 12.1667H0.5V0.5H12.1667V3H10.5V2.16667H2.16667V10.5H3V12.1667Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 3.83333H3.83333V15.5H15.5V3.83333ZM13.8333 5.5V7.16667H5.5V5.5H13.8333ZM8.83333 10.5V8.83333H10.5V10.5H8.83333ZM10.5 12.1667V13.8333H8.83333V12.1667H10.5ZM7.16667 10.5H5.5V8.83333H7.16667V10.5ZM12.1667 8.83333H13.8333V10.5H12.1667V8.83333ZM5.5 12.1667H7.16667V13.8333H5.5V12.1667ZM12.1667 13.8333V12.1667H13.8333V13.8333H12.1667ZM3 12.1667H0.5V0.5H12.1667V3H10.5V2.16667H2.16667V10.5H3V12.1667Z"
            fill="#323232"
          />
        </svg>
      )}
    </>
  );
};

export default BillingSVG;
