import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";

const ContactComponent: any = Loadable({
  loader: () => import("../../components/Contact/Contact"),
  loading: Loader,
});

const Contact = () => {
  return (
    <>
      <ContactComponent />
    </>
  );
};

export default Contact;
