import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import APIS from "../../constants/EndPoint";
import usePostHook from "../../customHooks/usePostHook";

type Props = {};

const AddCampaignComponent: any = Loadable({
  loader: () => import("../../components/SmsCampaign/AddSmsCampaign"),
  loading: Loader,
});

const AddCampaign = (props: Props) => {
  const { isPostLoading, mutateAsync } = usePostHook({
    queryKey: ["sms-campaign"],
    navigateURL: "/sms-campaign",
  });

  const handleSmsCampaignSubmit = async (data: any) => {
    const formData = {
      title: data.title,
      type: "sms",
      status: data.status,
      sender: data.sender,
      recipient: data.recipient,
      message: data.message,
    };
    try {
      await mutateAsync({
        url: `${APIS.ADD_CAMPAIGN}`,
        formData: formData,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <AddCampaignComponent
        handleSmsCampaignSubmit={handleSmsCampaignSubmit}
        isPostLoading={isPostLoading}
      />
    </>
  );
};

export default AddCampaign;
