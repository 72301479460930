import { NavLink, useLocation } from "react-router-dom";
import dibposLogo from "../../../assets/logo/dibtech-sms-and-emailmarketing.png";
import crossicon from "../../../assets/icons/cross.svg";
import { useEffect, useState } from "react";
import routerObject from "../../../routers/router";
import { tw } from "twind";
import SidebarButton from "../Button/SidebarButton";

type Props = {
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};
const Sidebar = (props: Props) => {
  const location = useLocation();
  const { setIsOpenSidebar } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(1);

  const handleClick = () => {
    setIsOpen(!isOpen);
    setIsActive(1);
  };
  const RenderIcon = ({ Icon, isActive }: any) => {
    return <Icon isActive={isActive} />;
  };

  return (
    <>
      <div className={tw`flex flex-col w-full sticky top-0 `}>
        <div className={tw`px-5`}>
          <div className={tw`flex py-5 items-center justify-between w-full`}>
            <img src={dibposLogo} alt="logo" />
            <span className={tw`lg:hidden `}>
              <SidebarButton
                icon={crossicon}
                setIsOpenSidebar={setIsOpenSidebar}
              />
            </span>
          </div>
          <div>
            <nav className={tw`flex-1 px-2 my-4 `}>
              {routerObject?.map((item: any, index: number) => (
                <NavLink
                  key={item.name}
                  to={item.path}
                  className={({ isActive, isPending }) =>
                    tw([
                      "group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md my-0.5",
                      isActive ? "bg-[#4D56AC] text-white" : "",
                      isPending ? "text-indigo-100 hover:bg-indigo-600" : "",
                    ])
                  }
                >
                  <RenderIcon
                    Icon={item?.icon}
                    isActive={
                      (item.path === "/" && location.pathname === "/") ||
                      (item.path !== "/" &&
                        location.pathname.includes(item?.path))
                        ? true
                        : false
                    }
                  />
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
