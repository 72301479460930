import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import usePostHook from "../../customHooks/usePostHook";
import moment from "moment";
import APIS from "../../constants/EndPoint";

type Props = {};
const QuickSmsComponent: any = Loadable({
  loader: () => import("../../components/QuickSms/QuickSms"),
  loading: Loader,
});

const QuickSms = () => {
  const { isPostLoading, mutateAsync } = usePostHook({
    queryKey: ["quick-sms"],
    navigateURL: "",
  });

  const handleQuicKSmsSubmit = async (data: any, reset: any) => {
    const dateTime =
      data.selection === "now"
        ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
        : moment(data.laterdate).format("YYYY-MM-DDTHH:mm:ssZ");
    const formData = {
      sender: data.sender,
      recipient: data.recipient,
      message: data.message,
      schedule: dateTime,
    };
    try {
      await mutateAsync({
        url: `${APIS.QUICK_SMS}`,
        formData: formData,
      });
      reset();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <QuickSmsComponent
        handleQuicKSmsSubmit={handleQuicKSmsSubmit}
        isPostLoading={isPostLoading}
      />
    </>
  );
};

export default QuickSms;
