import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import APIS from "../../constants/EndPoint";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import usePatchHook from "../../customHooks/usePatchHook";

type Props = {};

const EditEmailCampaignComponent: any = Loadable({
  loader: () => import("../../components/EmailCampaign/EditEmailCampaign"),
  loading: Loader,
});

const EditEmailCampaign = (props: Props) => {
  const { id } = useParams();

  const {
    isLoading: singleEmailCampaignLoading,
    data: singleEmailCampaignData,
  } = useGetHook({
    queryKey: ["email-campaign", `${id}`],
    url: `${APIS.CAMPAIGNLIST}${id}/`,
    params: {},
    isEnabled: !!id,
    isKeepPrevious: false,
  });

  const { isLoading, mutateAsync } = usePatchHook({
    queryKey: [],
    navigateURL: "/email-campaign",
  });

  const handleEmailCampaignSubmit = async (data: any) => {
    const formData = {
      id: id,
      title: data.title,
      type: "email",
      status: data.status,
      sender: data.sender,
      recipient: data.recipient,
      message: data.message,
      subject: data.subject,
    };
    try {
      await mutateAsync({
        url: `${APIS.UPDATE_CAMPAIGN}`,
        formData: formData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {singleEmailCampaignLoading ? (
        <Loader />
      ) : (
        <EditEmailCampaignComponent
          handleEmailCampaignSubmit={handleEmailCampaignSubmit}
          isLoading={isLoading}
          singleEmailCampaignData={singleEmailCampaignData}
          singleEmailCampaignLoading={singleEmailCampaignLoading}
        />
      )}
    </>
  );
};

export default EditEmailCampaign;
