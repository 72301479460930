import React from "react";

interface SpinnerProps {}

const Loader: React.FC<SpinnerProps> = (props) => {
  return (
    <div
      style={{ borderTopColor: "transparent" }}
      className={
        "w-10 h-10 border-4 border-blue-900 border-double rounded-full animate-spin m-auto"
      }
    ></div>
  );
};

export default Loader;
