const APIS = {
  CONTACTGROUP: "/contact-group/",
  BULK_CONTACT_GROUP_DELETE: "/contact-group/bulk-delete/",
  CONTACT: "/contact/",
  BULK_CONTACT_DELETE: "/contact/bulk-delete/",
  BULK_IMPORT_CONTACT: "/import-contacts/",
  CONTACT_TRANSFER: "/contact/transfer-contact/",
  QUICK_EMAIL: "/quick-mail/",
  QUICK_SMS: "/quick-message/",
  CAMPAIGNLIST: "/campaign/",
  ADD_CAMPAIGN: "/campaign/create-campaign/",
  UPDATE_CAMPAIGN: "/campaign/update-campaign/",
  DOWNLOAD_SAMPLE_FILE: "/download-file/",
};

export default APIS;
