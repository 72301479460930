import DashboardSVG from "../assets/svgComponent/DashboardSVG";
import ContactSVG from "../assets/svgComponent/ContactSVG";
import EmailCampaignSVG from "../assets/svgComponent/EmailCampaignSVG";
import BillingSVG from "../assets/svgComponent/BillingSVG";
import MyProfileSVG from "../assets/svgComponent/MyProfileSVG";
import QuickSmsSVG from "../assets/svgComponent/QuickSmsSVG";
import SmsCampaingSVG from "../assets/svgComponent/SmsCampaingSVG";

const routerObject = [
  { name: "DashBoard", icon: DashboardSVG, path: "/", children: [] },
  { name: "Contacts", icon: ContactSVG, path: "/contact", children: [] },
  {
    name: "Quick Email",
    icon: EmailCampaignSVG,
    path: "/quick-email",
    children: [],
  },
  {
    name: "Email Campaign",
    icon: EmailCampaignSVG,
    path: "/email-campaign",
    children: [],
  },
  { name: "Quick SMS", icon: QuickSmsSVG, path: "/quick-sms", children: [] },
  {
    name: "SMS Campaign",
    icon: SmsCampaingSVG,
    path: "/sms-campaign",
    children: [],
  },
  {
    name: "Billing",
    icon: BillingSVG,
    path: "/billing",
    children: [],
  },
];

export default routerObject;
