import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import usePaginationState from "../../customHooks/usePaginationState";
import APIS from "../../constants/EndPoint";
import useGetHook from "../../customHooks/useGetHook";
import { useSearchParams } from "react-router-dom";

type Props = {};
const SmsCampaignComponent: any = Loadable({
  loader: () => import("../../components/SmsCampaign/SmsCampaign"),
  loading: Loader,
});

const SmsCampaign = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || "";

  const { isLoading: smsCampaignListLoading, data: smsCampaignList } =
    useGetHook({
      queryKey: ["sms-campaign", pageSize, offset, searchQuery],
      url: `${APIS.CAMPAIGNLIST}`,
      params: {
        limit: pageSize,
        offset: offset,
        type: "sms",
        ...(searchQuery && { search: searchQuery }),
      },
      isKeepPrevious: false,
    });

  return (
    <>
      <SmsCampaignComponent
        data={smsCampaignList}
        smsCampaignListLoading={smsCampaignListLoading}
        offset={offset}
        setOffset={setOffset}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default SmsCampaign;
