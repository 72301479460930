import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import APIS from "../../constants/EndPoint";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import usePatchHook from "../../customHooks/usePatchHook";

type Props = {};

const EditCampaignComponent: any = Loadable({
  loader: () => import("../../components/SmsCampaign/EditSmsCampaign"),
  loading: Loader,
});

const EditSmsCampaign = (props: Props) => {
  const { id } = useParams();
  const {
    isLoading: singleEmailCampaignLoading,
    data: singleEmailCampaignData,
  } = useGetHook({
    queryKey: ["sms-campaign", `${id}`],
    url: `${APIS.CAMPAIGNLIST}${id}/`,
    params: {},
    isKeepPrevious: false,
  });

  const { isLoading, mutateAsync } = usePatchHook({
    queryKey: ["sms-campaign"],
    navigateURL: "/sms-campaign",
  });

  const handleEmailCampaignSubmit = async (data: any) => {
    const formData = {
      id: id,
      title: data.title,
      type: "sms",
      status: data.status,
      sender: data.sender,
      recipient: data.recipient,
      message: data.message,
    };
    try {
      await mutateAsync({
        url: `${APIS.UPDATE_CAMPAIGN}`,
        formData: formData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {singleEmailCampaignLoading ? (
        <Loader />
      ) : (
        <EditCampaignComponent
          handleEmailCampaignSubmit={handleEmailCampaignSubmit}
          isLoading={isLoading}
          singleEmailCampaignData={singleEmailCampaignData}
          singleEmailCampaignLoading={singleEmailCampaignLoading}
        />
      )}
    </>
  );
};

export default EditSmsCampaign;
