import Loadable from "react-loadable";
import Loader from "../../components/Reuseable/Loader/Loader";
import APIS from "../../constants/EndPoint";
import usePostHook from "../../customHooks/usePostHook";

type Props = {};

const AddEmailCampaignComponent: any = Loadable({
  loader: () => import("../../components/EmailCampaign/AddEmailCampaign"),
  loading: Loader,
});

const AddEmailCampaign = (props: Props) => {
  const { isPostLoading, mutateAsync } = usePostHook({
    queryKey: ["email-campaign"],
    navigateURL: "/email-campaign",
  });

  const handleEmailCampaignSubmit = async (data: any) => {
    const formData = {
      title: data.title,
      type: "email",
      status: data.status,
      sender: data.sender,
      recipient: data.recipient,
      message: data.message,
      subject: data.subject,
    };
    try {
      await mutateAsync({
        url: `${APIS.ADD_CAMPAIGN}`,
        formData: formData,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <AddEmailCampaignComponent
        handleEmailCampaignSubmit={handleEmailCampaignSubmit}
        isPostLoading={isPostLoading}
      />
    </>
  );
};

export default AddEmailCampaign;
