const SmsCampaingSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_613_288)">
            <path
              d="M18.3332 1.66667H1.6665V18.3333L4.99984 15H18.3332V1.66667ZM10.8332 11.6667H9.1665V10H10.8332V11.6667ZM10.8332 8.33333H9.1665V5H10.8332V8.33333Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_613_288">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_613_288)">
            <path
              d="M18.3332 1.66667H1.6665V18.3333L4.99984 15H18.3332V1.66667ZM10.8332 11.6667H9.1665V10H10.8332V11.6667ZM10.8332 8.33333H9.1665V5H10.8332V8.33333Z"
              fill="#323232"
            />
          </g>
          <defs>
            <clipPath id="clip0_613_288">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default SmsCampaingSVG;
