import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { patchApiData } from "../helpers/axiosInstance";

const usePatchHook = ({ queryKey, navigateURL }) => {
  const queryClient: any = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, mutateAsync, isSuccess, data, isError } = useMutation(
    patchApiData,
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          toast.success("Updated item successfully");
          if (navigateURL) {
            navigate(navigateURL);
          }
        } else {
          toast.error("Some things went wrongs");
        }
      },
      onError: (error: any) => {
        if (error.data && error.data.addresses) {
          const { city, line_address_one, country } = error.data.addresses[0];
          if (city) {
            toast.error("City is required field");
          }
          if (line_address_one) {
            toast.error("Line address one is required field");
          }
          if (country) {
            toast.error("Country is required field");
          }
        } else {
          toast.error("Some things went wrongs");
        }
      },
      onSettled: () => {
        if (queryKey && Array.isArray(queryKey)) {
          queryKey.forEach((key) => {
            queryClient.invalidateQueries(key);
          });
        }
      },
    }
  );
  return {
    isLoading,
    mutateAsync,
    isSuccess,
    data,
    isError,
  };
};

export default usePatchHook;
