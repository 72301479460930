import accounticon from "../../../assets/icons/account.svg";
import { removeLocalKey } from "../../../helpers/sessionKey";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { tw } from "twind";

const Avatar = () => {
  const handleLogOut = () => {
    removeLocalKey("token");
  };
  return (
    <>
      <Menu as="div" className={tw`relative my-auto `}>
        <Menu.Button className={tw`cursor-pointer focus:outline-none pt-1 `}>
          <img src={accounticon} alt="accounticon" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter={tw`transition ease-out duration-100`}
          enterFrom={tw`transform opacity-0 scale-95`}
          enterTo={tw`transform opacity-100 scale-100`}
          leave={tw`transition ease-in duration-75`}
          leaveFrom={tw`transform opacity-100 scale-100`}
          leaveTo={tw`transform opacity-0 scale-95`}
        >
          <Menu.Items
            className={tw`absolute right-1 rounded bg-white shadow-lg focus:outline-none`}
          >
            <div className={tw`px-1 py-1 flex flex-col`}>
              <Menu.Item>
                <a
                  href="/"
                  className={tw` whitespace-nowrap text-black px-2 py-1`}
                >
                  Back to Dashboard
                </a>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to="/billing"
                  className={tw` whitespace-nowrap text-black px-2 py-1`}
                >
                  Billing
                </Link>
              </Menu.Item>
              <Menu.Item>
                <a
                  onClick={handleLogOut}
                  href={"/auth-login"}
                  className={tw` whitespace-nowrap text-black px-2 py-1`}
                >
                  Logout
                </a>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default Avatar;
