import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/Dashboard/Dashboard";
import Contact from "../pages/Contact/Contact";
import DashboardLayout from "../layout/DashboardLayout";
import Billing from "../pages/Billing/Billing";
import QuicKEmail from "../pages/QuickEmail/QuicKEmail";
import EmailCampaign from "../pages/EmailCampaign/EmailCampaign";
import QuickSms from "../pages/QuickSms/QuickSms";
import SmsCampaign from "../pages/SmsCampaign/SmsCampaign";
import AddEmailCampaign from "../pages/EmailCampaign/AddEmailCampaign";
import EditEmailCampaign from "../pages/EmailCampaign/EditEmailCampaign";
import EditSmsCampaign from "../pages/SmsCampaign/EditSmsCampaign";
import AddSmsCampaign from "../pages/SmsCampaign/AddSmsCampaign";

// import PageNotFound from "../pages/ErrorPage/PageNotFound";

const router = createBrowserRouter(
  [
    {
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/quick-email",
          element: <QuicKEmail />,
        },
        {
          path: "/email-campaign",
          element: <EmailCampaign />,
        },
        {
          path: "/quick-sms",
          element: <QuickSms />,
        },
        {
          path: "/sms-campaign",
          element: <SmsCampaign />,
        },
        {
          path: "/billing",
          element: <Billing />,
        },
        {
          path: "/email-campaign/add",
          element: <AddEmailCampaign />,
        },
        {
          path: "/email-campaign/:id",
          element: <EditEmailCampaign />,
        },
        {
          path: "/sms-campaign/add",
          element: <AddSmsCampaign />,
        },
        {
          path: "/sms-campaign/:id",
          element: <EditSmsCampaign />,
        },
      ],
    },
  ],
  { basename: "/digital-marketing" }
);

export default router;
