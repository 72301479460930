//@ts-nocheck
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./routers";
import { setup } from "twind";

setup({
  theme: {
    extend: {
      colors: {
        primary: "#4D56AC",
      },
    },
  },
});

const queryClient = new QueryClient();

export default function Root() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ToastContainer autoClose={1000} />
      </QueryClientProvider>
    </>
  );
}
