//@ts-nocheck
import Sidebar from "../components/Reuseable/NavBar/SideBar";
import Navbar from "../components/Reuseable/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { tw } from "twind";
import { classNames } from "../utils/classNames";
import Footer from "../components/Reuseable/Footer";

type Props = {};

const DashboardLayout = (props: Props) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  return (
    <div className={tw`flex min-h-screen bg-[#f3f4f6]`}>
      <aside
        className={tw`w-1/5 z-10 m-4 rounded-lg bg-white `}
      >
        <Sidebar setIsOpenSidebar={setIsOpenSidebar} />
      </aside>
      <main className={tw`w-full flex flex-col justify-between`}>
        <section>
          <Navbar setIsOpenSidebar={setIsOpenSidebar} />
          <Outlet />
        </section>
        <Footer />
      </main>
    </div>
  );
};

export default DashboardLayout;
